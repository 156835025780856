<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__title">{{hasEditType ? 'Edit' : 'Create'}} blog</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--12">
							<div class="form__item">
								<form-input v-model="formData.alias"
										name="alias"
										@enter="onSave"
										rules="required"
										label="Alias"></form-input>
							</div>
						</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--8">
							<div class="lang">
								<div class="lang__tabs">
									<div class="lang__tab" v-for="lang in languages"
											:class="{'has-active': lang.code === selectedLang}"
											@click="selectedLang = lang.code">{{lang.code}}</div>
								</div>
								<div class="lang__cnt">
									<div class="lang__form" v-for="lang in languages" v-if="lang.code === selectedLang">
										<div class="grid">
											<div class="grid__coll grid__coll--12">
												<div class="form__item">
													<form-input v-model="formData.title[lang.code]"
															name="title"
															@enter="onSave"
															label="Blog title"></form-input>
												</div>
												<div class="form__item">
													<form-textarea v-model="formData.lead[lang.code]"
															height="195px"
															name="lead"
															@enter="onSave"
															label="Short description"></form-textarea>
												</div>

												<div class="grid__coll grid__coll--12">
													<div class="form__item">
														<form-editor v-model="formData.content[lang.code]"
																name="content"
																@enter="onSave"
																label="Blog text"></form-editor>
													</div>
												</div>
												<div class="grid__coll grid__coll--12">

													<div class="form__item">
														<form-input v-model="formData.meta_title[lang.code]"
																name="meta_title"
																@enter="onSave"
																label="SEO title"></form-input>
													</div>
												</div>
												<div class="grid__coll grid__coll--12">
													<div class="form__item">
														<form-textarea v-model="formData.meta_description[lang.code]"
																name="meta_desciprion"
																@enter="onSave"
																label="SEO desciprion"></form-textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="grid__coll grid__coll--4">
							<div class="form__item">
								<form-img v-model="formData.image"
										name="image"
										label="Preview"></form-img>
							</div>
						</div>
					</div>



					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': loadingSaveItem}"
								@click="onSave"><span>{{hasEditType ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'blog-save',
		props: ['item'],
		data(){
			return{
				selectedLang : this.$store.state.selectedLang,
				formData:{
					alias: null,
					image: null,
					title: {},

					lead: {},
					content: {},

					meta_title: {},
					meta_description: {},
					is_published: 1
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.blogs.loadingSaveItem,
				languages: state => state.languages.list,
			}),

			...mapGetters({
				currentTypography: 'currentTypography',
				hasAdminTypeTypography: 'hasAdminTypeTypography'
			}),

			hasEditType(){
				return !!this.item;
			},
		},

		created(){
			if(this.hasEditType) this.setFormData()
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'blogs/SAVE_ITEM'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						let formData = $fn.cloneDeep(this.formData);

						this.SAVE_ITEM($fn.assign(formData,{
							id: this.hasEditType ? this.item.id : null,

						})).then((resp) =>{
							this.$emit('close');

						}).catch(err => {
							$fn.setValidateErrors(err, this.errors);
						})
					}
				});
			},

			setFormData(){
				$fn.assign(this.formData, this.item);
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
