<template>
	<div class="section">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Blogs</div>
						<div class="section__options">
							<div class="section__option">
								<button class="btn btn--sm" @click="onSave(null)">Create</button>
							</div>
						</div>
					</div>

					<div class="section__sub">
						<div class="section__infoWrap">
							<div class="filter">
								<div class="filter__wrap">
									<div class="filter__item">
										<form-input v-model="title"
												name="title"
												label="Name"
												:filterType="true"></form-input>
									</div>

									<div class="_flex _justify-end" v-if="hasFilterRun">
										<button class="btn btn--mini" @click="resetFilter">Clear</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="section__main">
						<v-table :headers="tabelHeaders"
								:items="list"
								:hasLoadList="loadingGetList"
								:pagination="listPagination"
								@changeLimit="onPaginationChangeLimit"
								@changePage="onPaginationChangePage">
							<tr v-for="(td, index) in list" :key="index">
								<td>{{td.id}}</td>
								<td>{{td.title}}</td>
								<td>{{td.alias}}</td>
								<td>{{td.lead}}</td>
								<td>
									<div class="table__options">
										<div class="table__option" @click="onSave(td)">
											<v-icon name="edit"></v-icon>
										</div>

										<div class="table__option">
											<alert-modal :class="{'has-disabled': loadingDeleteItem}" @success="onDelete(td.id)">
												<v-icon name="trash-alt"></v-icon>
											</alert-modal>
										</div>
									</div>
								</td>
							</tr>
						</v-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import VTable from '@components/VTable';
	import BlogSave from './BlogSave';

	export default {
		name: 'blogs-index',
		components:{
			VTable,
			BlogSave
		},
		data(){
			return {
				tabelHeaders: [
					{key:'id', text: 'ID'},
					{key:'title', text: 'Title',},
					{key:'alias', text: 'Alias',},
					{key:'lead', text: 'Short Description',},
					{key:'nav', text: ''},
				]
			}
		},
		computed:{
			...mapState({
				list: state => state.blogs.list,
				listPagination: state => state.blogs.listPagination,
				listFilter: state => state.blogs.listFilter,
				loadingGetList: state => state.blogs.loadingGetList,
				loadingDeleteItem: state => state.blogs.loadingDeleteItem,
			}),

			...mapGetters({
				listActiveFilter: 'blogs/listActiveFilter',
				currentTypography: 'currentTypography',
				hasAdminTypeTypography: 'hasAdminTypeTypography'
			}),

			title:{
				get(){
					return this.listFilter.title
				},
				set(val){
					this.SET_FILTER_LIST({key: 'title', val: val});
					this.getList();
				}
			},

			hasFilterRun(){
				return this.catalogTitle || this.is_published;
			}
		},

		created(){
			this.initCommonData();
			this.getFilterDataFromUrl();
			this.getList();
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'blogs/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_LIST: 'blogs/GET_LIST',
				GET_TYPOGRAPHIES: 'typographies/GET_LIST',
				GET_ITEM: 'blogs/GET_ITEM',
				DELETE_ITEM: 'blogs/DELETE_ITEM',
			}),


			onSave(item){
				if (item) {
					this.GET_ITEM(item.id).then(resp => {
						this.openModalSave(resp.single);
					})

				} else {
					this.openModalSave();
				}
			},

			onSave(item){
				if (item) {
					this.GET_ITEM(item.id).then(resp => {
						this.openModalSave(resp.single);
					})

				} else {
					this.openModalSave(item);
				}
			},

			openModalSave(item){
				this.$modal.show(BlogSave,{
					item: item
				}, $MODAL_OPTIONS.full);
			},

			onDelete(id){
				this.DELETE_ITEM(id).then(() => {
					if(this.list.length === 1)this.setFilterDataToUrl();
				});
			},

			onChangeTypography(){
				this.getList();
			},

			getList: $fn.debounce(function () {
				this.GET_LIST();
				this.setFilterDataToUrl();
			}, 500),

			getFilterDataFromUrl(){
				let query = this.$route.query;

				$fn.forIn(query,(val, key) => {
					this.SET_FILTER_LIST({key: key, val: val});
				})
			},

			setFilterDataToUrl(){
				this.$router.replace({ name: this.$route.name, query: this.listActiveFilter }).catch(() => {});
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			},

			initCommonData(){
			},

			resetFilter(){
				this.title = null;
			}
		}
	};
</script>

<style scoped lang=scss>

</style>
